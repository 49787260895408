/* SASS variables */

/* Path */
$theme-path: '/_themes/design2016';
$assets-path: '/assets';

/* Colour palette */
$color-primary: #61703f;
$color-secondary: #3b341c;
$color-tertiary: #b39f35;
$color-bodybg: #fff;

/* Default Text */
$default-fontstack: 'Helvetica Neue',Helvetica,Arial,sans-serif;
$default-fontsize: 14px;
$default-fontweight: 400;
$default-lineheight: 1.5;
$default-fontcolor: #3b341c;

/* Link Text */
$color-link: #006699;
$color-active: #006699;
$color-hover: #006699;

/* Headings */
$header-fontstack: 'Humanist521BT-Light',sans-serif;
  $header-fontcolor: #403d3e;

/* Header */
$color-header-bg: #ffffff;
  $headernav-height: 40px;
  $headerlogo-height: 180px;
  $header-height: 180px;
  $header-img-height: 205px;

/* Slideshow */
$home-slider-height: 720px;
$home-slider-height-medium: 480px;
$home-slider-height-small: 360px;
$sub-slider-height: 420px;
$sub-slider-height-medium: 400px;
$sub-slider-height-small: 260px;
$sub-slider-height-tiny: 240px;

/* Tagline */
$tagline-fontstack: 'adobe-garamond-pro','Georgia',serif;
$tagline-fontsize: 60px;
$tagline-fontsize-768: 40px;
$tagline-fontsize-480: 30px;
  $tagline-lineheight: 64px;
  $tagline-lineheight-768: 54px;
  $tagline-lineheight-480: 44px;
$tagline-fontweight: 400;

/* Navigation */
$nav-fontstack: 'Humanist521BT-Light',sans-serif;
$nav-fontsize: 20px;
$nav-fontsize-768: 16px;
$nav-fontsize-980: 18px;
$nav-color: #ffffff;
$color-navbar-bg: $color-primary;
$color-dropdown: $color-primary;
$navbar-height: 44px;
  $nav-padding: 10px;
  $subnav-height: 35px;
  $subnav-extra-height: 55px;

/* Footer */
  $footer-fontsize: 16px;
  $color-footer-text: #000000;
$color-footer-bg: #b2b1a1;
  $footer-height: 150px;

/* Generic colour palette */
$color-black: #000000;
$color-grey: #9f9f9f;
$color-darkgrey: #515151;
$color-blue: #5c7589;
$color-gold: #a99970;
$color-yellow: #ffcc00;
$color-white: #ffffff;

  /* Additional components */
  $color-button-primary: #ce3734;
  $block-summary-fontsize: 20px;
  $block-summary-line-height: 30px;

/* Breakpoints */
$mini: 320px;
$small: 480px;
$medium: 768px;
$large: 960px;
$xlarge: 1220px;

/* SASS Mixins */
/* Vertical align center everything */
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin preserve-3d {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

  /* Text shadow */
  @mixin textshadow($offset-y,$offset-x,$blur,$color,$opacity){
    text-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  }

  /* Opacity and colour */
  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

@mixin box-shadow($offset-y,$offset-x,$blur,$color,$opacity) {
  -webkit-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  -moz-box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  box-shadow: $offset-y $offset-x $blur rgba($color, $opacity);
  background-clip: padding-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

// Single side border-radius
@mixin border-topleft-radius($radius) {
   -webkit-border-top-left-radius: $radius;
   -moz-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-topright-radius($radius) {
   -webkit-border-top-right-radius: $radius;
   -moz-border-top-right-radius: $radius;
   border-top-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomright-radius($radius) {
   -webkit-border-bottom-right-radius: $radius;
   -moz-border-bottom-right-radius: $radius;
   border-bottom-right-radius: $radius;
   background-clip: padding-box;
}
@mixin border-bottomleft-radius($radius) {
   -webkit-border-bottom-left-radius: $radius;
   -moz-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
