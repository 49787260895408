/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ General Site ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Backgrounds */
.header-bg {background: rgba(255,255,255,0.75); }
.main-bin {background: $color-bodybg}

/* Typography */
html {overflow-y: scroll; background: $color-footer-bg;}
html, body {margin: 0; padding: 0; line-height: $default-lineheight; font-size: $default-fontsize; font-weight: $default-fontweight; color: $default-fontcolor}
body {font-family: $default-fontstack}

small {font-size:12px;}

/* Text Headers */
h1 {font-family: 'adobe-garamond-pro','Georgia',serif; color: $color-secondary; font-size: 48px; line-height: 48px; font-weight: 400; padding: 0; margin: 0}
h2 {font-family: $header-fontstack; color: $color-primary; font-size: 28px; line-height: 28px; font-weight: 300; padding: 0; margin: 0}
h3 {font-family: $header-fontstack; color: #483a34; font-size: 16px; line-height: 28px; font-weight: 600; padding: 10px 0 0 0; margin: 0}
h4 {font-family: 'adobe-garamond-pro','Georgia',serif; color: #35582d; font-size: 20px; line-height: 32px; font-weight: 400; padding: 0; margin: 0}

/* links */
a {color: $color-link}
.uk-active a,
a:active {text-decoration: underline; color: $color-active;}
a:hover {text-decoration: underline; color: $color-hover;}

/* Colour stuff */
.color-primary {color: $color-primary !important;}
.color-secondary {color: $color-secondary !important;}
.color-tertiary {color: $color-tertiary !important;}
.bg-primary {background-color: $color-primary !important;}
.bg-secondary {background-color: $color-secondary !important;}
.bg-tertiary {background-color: $color-tertiary !important;}
.bg-white {background-color: $color-white !important;}
.white-text {color: #ffffff;}
.black-text {color: #000000;}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Navigation ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Sticky nav */
.navbar.sticky-nav {position: fixed; left: 0; top: 0; display: block; z-index: 2;}
.header-bg.sticky-nav {margin-top: $navbar-height;}

/* Off-canvas nav */
.mobile-nav {position: absolute; width: inherit; height: 30px; right: 50px; top: 25px;}
.mobile-nav a {color: $color-primary;}
.uk-offcanvas-bar {background: #dddddd;}
.uk-nav-offcanvas > li > a {color: #333}
.uk-nav-offcanvas>.uk-open>a, html:not(.uk-touch) .uk-nav-offcanvas>li>a:focus, html:not(.uk-touch) .uk-nav-offcanvas>li>a:hover {background: darken($color-navbar-bg,15%); color: $color-primary}
.uk-nav-offcanvas ul a span {font-family: $nav-fontstack; color: rgba(0,0,0,0.6)}
.uk-nav-offcanvas ul a span:hover {color: rgba(0,0,0,1)}
.uk-nav-header .uk-panel {margin: 0}
.burgers {position: absolute; left: 0; top: 0; text-align: left; font-size: 30px}
.uk-navbar-toggle {float: none; font-size: 22px}
.uk-nav-offcanvas>li {font-size: 16px}
.uk-offcanvas {}
.uk-offcanvas li.uk-nav-divider {border-top: 1px solid #222; border-bottom: 1px solid #555; border-width: 1px 0}
.uk-offcanvas .legal {color: #333; font-size: 12px}

/* Main nav */
.navbar {width: 100%; height: $navbar-height; position: relative; font-family: $nav-fontstack;}
.navbar {background-color: rgba($color-navbar-bg,0.6);}

/* Main navigation */

/* Default settings */
ul.main-nav {list-style: none; margin: 0 0 0 20px; padding: 0;}
.main-nav * {list-style: none; margin: 0; padding: 0;}
.main-nav li {position: relative;}
.main-nav ul {position: absolute; display: none; top: 100%; left: 0; z-index: 99;}
.main-nav > li {float: left;}
.main-nav li:hover > ul, .main-nav li.hover > ul {display: block;}
.main-nav a {display: block; position: relative;}
.main-nav > ul {top: 0; left: 100%; transition: all .2s ease-in-out;}

/* Customized based on theme */
.main-nav > li {height: $navbar-height; margin: 0; padding: 0;}
.main-nav > li > a {display: block; color: $nav-color; margin: 0; padding: 0 20px; line-height: $navbar-height; font-size: $nav-fontsize; font-weight: 400; }
.main-nav > li:hover {background-color: $color-navbar-bg;}
.main-nav > li:hover, .main-nav > li > a:hover, .main-nav > li:hover > a, .main-nav > li > a:focus {color: #b7ba5f; text-decoration: none; text-decoration: none;}
.main-nav > li.uk-active > a {color: #b7ba5f; text-decoration: none;}
.main-nav li.last {padding-right: 0}

/* Secondary level */
.main-nav ul {margin-left: 1px; font-family: $default-fontstack; min-width: 200px; background: $color-dropdown; text-align: left; padding: 8px; left: -1px;}
.main-nav ul li, .main-nav ul li a {color: rgba($color-white,0.5); font-size: 14px; text-decoration: none; }
.main-nav ul > li.uk-parent > a {display: block}
.main-nav li:hover ul, .main-nav > li a:hover ul, .main-nav > li:hover a ul {display: block;color: rgba($color-white,1);}

/* Children */
.main-nav li li > a {color: #ffffff; padding: 5px 20px;font-weight: normal; text-transform: none}
.main-nav li li > a:hover, .main-nav li li:hover > a, .main-nav li li.uk-active > a {color: #b7ba5f;}

/* Tertiary level */
.main-nav ul ul {display: block; position: relative; left: 0; background: none; padding: 0 20px;}

/* Side Nav */
#page-aside {max-width: 250px;}
#page-aside #subnav > ul {padding-bottom: 40px;}
#page-aside #subnav ul li {padding: 8px 0; border-top: 1px solid #b9d2d5;}
#page-aside #subnav ul li a {display: block;}
#page-aside #subnav ul li a:hover {color: #001723; text-decoration: none; text-shadow: 0px 0px 1px rgba(0, 23, 35, 0.4);}
#page-aside #subnav ul li.uk-active a {color: #001723; text-decoration: none;}
#page-aside #subnav ul li span {width: 18px; display: inline-block; -webkit-transition: width 1s, padding 1s; transition: width 1s, padding 1s;}
#page-aside #subnav ul li a:hover span,
#page-aside #subnav ul li.uk-active a span {width: 28px; padding-left: 10px;}

#page-aside #subnav ul li ul {list-style: disc;margin-left:16px; padding-left: 0; border-bottom: none}
#page-aside #subnav ul li ul li {border-top: none}


/* Footer nav */
.footer-nav, .footer-nav * {list-style: none; margin: 0; padding: 0;}
.footer-nav li {padding-right: 20px; float: left}
.footer-nav li a {color: #ffffff; font-size: 14px; line-height: 18px; font-weight: 400;}
.footer-nav li.uk-active a,
.footer-nav li a:hover {color: rgba(255,255,255,0.7); text-decoration: none;}


/* Mobile subnav */
#subnav-selector {margin-top: 2px !important}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Sliders and Headers ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Banner Images */
.banner {width: 100%; height: $sub-slider-height; top: 0; overflow: hidden;}
.home .banner {height: $home-slider-height;}

/* Tagline */
.tagline-bin {height: $sub-slider-height - 139px; position: relative;}
.home .tagline-bin {height: $home-slider-height - 139px;}
.tagline {position: absolute; width: 100%}
.tagline.v-top {top: 10%;}
.tagline.v-middle {top: 25%;}
.tagline.v-bottom {bottom: 10%;}
.tagline h2 {padding: 0; margin: 0; font-family: $tagline-fontstack; font-weight: $tagline-fontweight; font-size: $tagline-fontsize; line-height: $tagline-lineheight; text-align: center}

/* Sliders */
#slider {width: 100%; height: $home-slider-height;}
.home #slider {width: 100%; height: $home-slider-height;}
.home .slide {width: 100%; height: $home-slider-height}

/* Caption Box */
.caption {}
.caption.v-top {margin-top: 175px;}
.caption.v-middle {margin-top: 385px;}
.caption.v-bottom {margin-top: 590px;}
.caption h2 {padding: 10px; color: #fff; font-weight: 300; font-size: 36px; line-height: 42px; background: rgba(0,0,0,0.5);}
.caption a {display: inline-block; padding: 12px 16px; margin-top: 5px; color: #fff; font-size: 15px; line-height: 18px; background: #54a046;}
.caption a:hover {color: #fff; background: $color-primary; text-decoration: none; }

	ul#slider-nav  {width: auto; margin-top: -36px; left: 50%; list-style-type: none; position: absolute; z-index: 105}
	ul#slider-nav li {padding: 5px; float: left}
	ul#slider-nav li a {display: block; width: 15px; height: 15px; color: #0e94db; font-size: 16px; background: $color-white; border: 3px solid $color-white;@include border-radius(7px);}
	ul#slider-nav li.cycle-pager-active a {background: $color-secondary}
.slider-buttons {background: #ffffff;}
.slider-buttons a {padding: 10px; color: $color-primary; background: #ffffff;}

/* Logo */
.logo {padding: 20px; display: block;}

/* Navbar Search box */
.header-bg .uk-search-field {border: 1px solid #2a2a2a; background-color: #5d5d5d; @include border-radius(4px); width: 130px; height: 35px; margin-top: 5px; padding: 0 30px 0 6px; font-size: 13px;}
.header-bg .uk-search-field:focus {background-color: rgba($color-white,0.9); width: 200px;}
.header-bg .uk-search:before {left: auto; right: 0; top: 6px;}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Page Structure ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

div.header { position: absolute; left: 0; right: 0; top: 0; z-index: 101}
.logo-off img {background: rgba(255,255,255,0.65);
	-webkit-box-shadow: 0px 0px 20px 1px rgba(255,255,255,1);
-moz-box-shadow: 0px 0px 20px 1px rgba(255,255,255,1);
box-shadow: 0px 0px 20px 1px rgba(255,255,255,1);}
.search-form input,
.search-form .uk-form-icon {background: none !important; border: 1px solid $color-primary}
.search-form input:focus {background: #ffffff !important}

#page-title {padding: 40px 0;}

#page-content span[style] {line-height: auto}
#page-content h2 {padding-bottom: 20px;}

/* List */
.item-list {line-height: normal;}
.item-list a {text-decoration: underline;}

/* Inset Content */
.inset-content {width: 300px; margin-left: 10px;}
.testimonial  {width:300px; padding:10px 0; margin:0 0 20px 0; color:#35582d; font-size:14px; line-height:20px; font-weight:normal; border-top:1px solid #35582d;border-bottom:1px solid #35582d;}
.testimonial p {margin-bottom: 0}
.testimonial em {padding-left: 5px;color:#35582d;font-family:'adobe-garamond-pro','Georgia',serif;font-size:14px;line-height:20px;font-weight:normal}
.testimonial span.uk-icon-quote-left {font-size:24px}
.testimonial .uk-icon-quote-right {font-size:24px; float: right}

/* Footer */
#page-footer {min-height: $footer-height; line-height: 16px; color: $color-footer-text; font-size: $footer-fontsize; background: $color-footer-bg}
.footer-address strong {color: $color-primary;}
.legal a {color: #5a5a5a; font-size: 13px;}
.adnet a {font-size: 12px; color: rgba(#5a5a5a,0.5);}
.adnet a:hover {color: rgba(#5a5a5a,1);}
.footer-image {width: 100%; height: 680px;}
.footer-image .footer-caption {padding: 30px; margin-top: 15%; color: $color-primary;font-size: 26px; line-height: 30px; background: #fff; text-align: center;}
.footer-image .footer-caption a {color: $color-primary;}
.footer-image > div > div > div {margin: 0 auto}

/* Social media */
.icons {z-index: 2;}
.social {display: block; float: right; list-style: none; margin: 8px 0 0; padding: 0; vertical-align: middle;}
.social li {display: inline-block; margin: 0; padding: 0; width: 24px; height: 24px; text-align: center; line-height: 24px; @include border-radius(4px);}
.social li a {display: block; color: $color-white; width: 24px; height: 24px;}
.social li i {margin-top: 3px;}
.facebook {background-color: #3B5998; color: $color-white;}
.linkedin {background-color: #007bb6; color: $color-white;}
.twitter {background-color: #4099ff; color: $color-white;}
.flickr {background-color: #007bb6; color: $color-white;}
.instagram {background-color: #007bb6; color: $color-white;}
.youtube {background-color: #cd201f; color: $color-white;}
.pinterest {background-color: #BD081C; color: $color-white;}
.rss {background-color: #f60; color: $color-white;}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Home Page ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Home Page Text */
.home-text a.more-link {display: inline-block; padding: 0 30px; color: #fff; font-size: 16px; line-height: 50px; font-weight: 400; background: rgba($color-primary,1);}
.home-text a.more-link:hover {background: rgba($color-primary,0.43);}
.home-text h1 {font-family: $header-fontstack; color: $color-primary; font-size: 24px; line-height: 28px; font-weight: 300;}

/* Feature boxes - Home Page */
a.feature {display: block;}
a.feature .background-image {height: 225px;}
.side-features a.feature .background-image {height: 120px;}
a.feature h3 {padding-left: 15px; color: #ffffff; font-size: 18px; line-height: 40px;}
a.feature:hover h3 {color: rgba(255,255,255,0.6); text-decoration: none;}
a.feature:hover {text-decoration: none;}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Sub-Pages ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* Place Page */
.place-text {padding: 25px;}
.place-text h3 {color: #fff; font-size: 28px; font-weight: 300; line-height: 28px;}
.place-text p {color: #fff; line-height: 24px;}

.job-opportunities-page .uk-panel {max-width: 400px; background: #f5f5f5}

img.team {margin: 0 0 10px 10px;}

.elc-page #page-title {display: none}
.elc-page #page-content {padding-top: 15px;}

ul.donate li:first-child {border-right: 1px solid #ddd}

/* Flickr gallery */
ul#flickr {list-style: none; margin: 0; padding: 0}
#flickr li {position:relative;margin:0 0 20px 0;padding:0;width:100%; height: 150px; float: left;overflow: hidden;}
#flickr li {background: #3c4725}
#flickr li:nth-child(1) {background: #4b8b94}
#flickr li:nth-child(2) {background: #517547}
#flickr li:nth-child(3) {background: #3b351d}
#flickr li:nth-child(4) {background: #3c4725}
#flickr li:nth-child(5) {background: #4b8b94}
#flickr li:nth-child(6) {background: #517547}
#flickr li:nth-child(7) {background: #3b351d}
#flickr li a {display: block; width: 100%; height: 150px; position: absolute; background-position: 0 0; cursor: pointer}
#flickr li.odd a {text-align: right;}
#flickr li a img {display: block; float: left; width: 50%; }
#flickr li.odd a img {display: block; float: right;}
#flickr li a .title {position: relative; font-family:'Humanist521BT-Light',sans-serif;font-size:28px; line-height: 28px; font-weight: 300; float: left; width: 269px; margin: 20px 10px 10px 20px}
#flickr li.odd a .title {float: right;}
#flickr li a .desc {display:none;}
#flickr li a {color: #fff}
#flickr li a:hover {color: #a8b3ce}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Misc Crap ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.bordered {padding: 4px; border: 1px solid #dddddd; }
a.link-panel {background: #f5f5f5; border: 2px solid #f5f5f5}
a.link-panel:hover {color: $color-primary; border: 2px solid $color-primary}
a.link-panel span {padding-right: 15px;}

table.c-table th {color: #fff; text-align: left; background: $color-primary}
table.c-table tr:nth-child(even) {background: #f5f5f5}

input.radio {margin: 2px 5px 0 0;}

/* Parsley overrides */
input.parsley-success,select.parsley-success,textarea.parsley-success {color: #468847;background-color: #DFF0D8;border: 1px solid #D6E9C6}
input.parsley-error,select.parsley-error,textarea.parsley-error{color:#B94A48;background-color:#F2DEDE;border: 1px solid #EED3D7}
.parsley-errors-list {margin: 2px 0 3px;padding: 0; color: #c00;list-style-type: none;font-size: 0.9em;line-height: 0.9em;opacity: 0;transition: all .3s ease-in;-o-transition: all .3s ease-in;-moz-transition: all .3s ease-in;-webkit-transition: all .3s ease-in}
.parsley-errors-list.filled {opacity:1}
.footer-features .parsley-errors-list {position: absolute; left:0;bottom:-25px}
#contact .parsley-errors-list {margin-top: 5px}

/* Join button in footer */
.join-list > .uk-button-primary {font-size: 15px; font-weight: 400; line-height: 40px; background: $color-primary !important; transition: background 2s; border: none; border-radius: 0}
.join-list > .uk-button-primary:hover {background: rgba($color-primary,0.6) !important}
.join-list > .uk-button-primary span {padding: 0 5px 0 15px; line-height: 40px;}

/* Form */
.uk-form {padding: 30px 20px 0 0;}

/* uikit overrides */
em {color: inherit}
.uk-accordion-title {cursor: pointer}
.uk-accordion-title::after {position: absolute; top: 5px; right: 10px; color: $color-primary; font-family: 'FontAwesome'; font-size: 20px; content: "\f067"; float: right}
.uk-accordion-title.uk-active::after {content: "\f068";}
#page-content .uk-accordion-title h2 {padding-bottom: 0}
#page-content .uk-accordion-title h3 {padding-top: 0}
figcaption.uk-overlay-background {max-width: 400px; margin: 0 5px 5px 5px;}

.contact-info-page .uk-accordion-title h2 {padding-left: 10px; line-height: 48px; background: #f5f5f5;}
.contact-info-page .uk-accordion-title::after {font-size: 16px;}

/* Photo Gallery */
.gallery-image {margin-bottom: 10px; overflow: hidden}

/* Tables */

/* Pop up form */

/* Misc */
.iframe-container {position: relative; padding-bottom: 56.25%; padding-top: 35px; height: 0; overflow: hidden;}
.iframe-container iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

ul.uk-list-striped li.year-header {background: none; border-bottom: 1px solid $color-primary}
.uk-table-striped table tbody tr:nth-of-type(odd) {background: none}
.uk-table th { background: #61703f; color: #FFFFFF; }
.uk-table-striped tbody tr:nth-of-type(2n+1) { background: #e6eed6; }

.alert-success { background: green none repeat scroll 0 0; color: #ffffff; padding: 30px; }

#mce-success-response {
    background: #c6dcc6 none repeat scroll 0 0;
    border: 1px solid green;
    border-radius: 5px;
    color: green;
    font-weight: bold;
    padding: 20px;
}

.alert-danger {
    color: red;
}

/* Media queries */
@media (min-width: 0px) and (max-width: $mini - 1) {
	.banner {height: $sub-slider-height-small;}
	.home .banner {height: $home-slider-height-small;}
	.home #slider,
	.home .slide {height: $home-slider-height-small;}
	.tagline-bin {height: $sub-slider-height-small - 95px;}
	.home .tagline-bin {height: $home-slider-height-small - 95px;}
    .feature {min-height: 160px;}
	.footer-image {height: 360px;}
	.footer-image .caption {padding: 30px; font-size: 18px; line-height: 24px;}
	.tagline h2 {font-size: $tagline-fontsize-480; line-height: $tagline-lineheight-480}
	.tagline.v-middle {top: 25%;}
	.footer.v-bottom {bottom: 0;}
	.caption h2 {font-size: 18px; line-height: 24px;}
	.caption.v-top {margin-top: 110px;}
	.caption.v-middle {margin-top: 200px;}
	.caption.v-bottom {margin-top: 240px;}
}

@media (min-width: $mini) and (max-width: $small - 1) {
	.banner {height: $sub-slider-height-small;}
	.home .banner {height: $home-slider-height-small;}
	.home #slider,
	.home .slide {height: $home-slider-height-small;}
	.tagline-bin {height: $sub-slider-height-small - 95px;}
	.home .tagline-bin {height: $home-slider-height-small - 95px;}
    .feature {min-height: 160px;}
	.place-bin {max-width: 400px;}
	.place-image img {width: 100%;}
	.footer-image {height: 360px;}
	.footer-image .caption {padding: 30px; font-size: 18px; line-height: 24px;}
	.tagline h2 {font-size: $tagline-fontsize-480; line-height: $tagline-lineheight-480}
	.tagline.v-middle {top: 25%;}
	.slider.v-bottom {bottom: 0;}
	.caption h2 {font-size: 18px; line-height: 24px;}
	.caption.v-top {margin-top: 110px;}
	.caption.v-middle {margin-top: 200px;}
	.caption.v-bottom {margin-top: 240px;}
}

@media (min-width: $small) and (max-width: $medium - 1) {
	.banner {height: $sub-slider-height-small;}
	.home .banner {height: $home-slider-height-small;}
	.home #slider,
	.home .slide {height: $home-slider-height-small;}
	.tagline-bin {height: $sub-slider-height-small - 95px;}
	.home .tagline-bin {height: $home-slider-height-small - 95px;}
    .feature {min-height: 160px;}
	.place-bin {height: 360px; overflow: hidden;}
	.place-text {height: 360px; padding: 25px;}
	.place-image img {min-height: 360px; width: auto; max-width: none;}
	.footer-image {height: 480px;}
	.tagline h2 {font-size: $tagline-fontsize-480; line-height: $tagline-lineheight-480}
	.tagline.v-middle {top: 25%;}
	.slider.v-bottom {bottom: 0;}
	.caption h2 {font-size: 18px; line-height: 24px;}
	.caption.v-top {margin-top: 110px;}
	.caption.v-middle {margin-top: 200px;}
	.caption.v-bottom {margin-top: 265px;}
}

@media (min-width: $medium) and (max-width: $large - 1) {
    .main-nav > li > a {padding: 0 8px; font-size: $nav-fontsize-768}
	.banner {height: $sub-slider-height-medium;}
	.home .banner {height: $home-slider-height-medium;}
	.home #slider,
	.home .slide {height: $home-slider-height-medium;}
	.tagline-bin {height: $sub-slider-height-medium - 139px;}
	.home .tagline-bin {height: $home-slider-height-medium - 139px;}
	#page-aside {padding: 144px 30px 0 0;}
	.inset-photos {max-width: 200px;}
	.inset-content,
	.testimonial  {width: 200px;}
	.footer-image {height: 680px;}
	.place-bin {height: 300px; overflow: hidden;}
	.place-text {height: 300px; padding: 40px;}
	.place-image img {min-height: 300px; width: 100%}
	.caption h2 {font-size: 24px; line-height: 28px;}
	.caption.v-middle {margin-top: 275px;}
	.caption.v-bottom {margin-top: 380px;}
}

@media (min-width: $large) and (max-width: $xlarge - 1) {
	.main-nav {margin-left: 10px; padding: 0;}
    .main-nav > li > a {padding: 0 15px; font-size: $nav-fontsize-980;}
	#page-aside {padding: 144px 50px 0 0;}
	.place-bin {height: 300px; overflow: hidden;}
	.place-text {height: 300px; padding: 40px;}
	.place-image img {min-height: 300px; width: 100%}
}

@media (min-width: $xlarge) {
    .main-nav > li > a {padding-right: 30px;}
	#page-aside {padding: 144px 50px 0 0;}
	.place-bin {height: 360px; overflow: hidden;}
	.place-text {height: 360px; padding: 50px;}
	.place-image img {min-height: 360px; width: 100%}
}

@media (min-width: 1080px) and (max-width: 1219px) {
	.testimonial  {width:360px;}
}

@media (min-width: 1220px) and (max-width: 1339px) {
	.testimonial  {width:330px;}
}

@media (min-width: 1340px) and (max-width: 1459px) {
	.testimonial  {width:360px;}
}

@media (min-width: 1460px) {
	.testimonial  {width:420px;}
}
