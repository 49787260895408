/**
 * @license
 * MyFonts Webfont Build ID 2903720, 2014-10-16T17:10:07-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Humanist521BT-Light by ParaType
 * URL: http://www.myfonts.com/fonts/paratype/humanist-521-bt/light-128520/
 * Copyright: Copyright 1987-1992 Bitstream Inc. All rights reserved. Partial Copyright &#x00A9; 1997, ParaType Inc, ParaType Ltd. All rights reserved.
 * Licensed pageviews: 10,000
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2903720
 * 
 * © 2014 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2c4ea8");

  
@font-face {font-family: 'Humanist521BT-Light';src: url('webfonts/2C4EA8_0_0.eot');src: url('webfonts/2C4EA8_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/2C4EA8_0_0.woff') format('woff'),url('webfonts/2C4EA8_0_0.ttf') format('truetype');}
 